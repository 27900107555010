:root{
    --calendar-day-y-padding: 3.5rem;
    --calendar-day-x-padding: 0.6rem;
    --calendar-day-background-color: #fafafa;
    --filler-day-background-color: #f5f5f5;
}
.calendar-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 100%;
    padding: 0.25rem;
    gap: 0.25rem;
}

.calender-header{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.icon-button{
    background: transparent;
    border: none;
    color: black;
    hover: pointer;
}

.icon{
    width: 0.8rem;
    height: 0.8rem;
    padding: 0.1rem;
}


.month-picker{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 8rem;
}
.year-picker{
    text-align: center;
    align-items:center;
    justify-content: space-between;
    font-size: 1.25rem;
    display: flex;
    gap:2rem;
    position:relative;

}


.calendar-body{
    display: grid;
    grid-template-columns:  repeat(7, minmax(0,1fr));
    width: 100%;

}

.calendar-body-header-item {
    padding: 0.6rem;
    text-align: center;
    background: #fefefe;
    border-left-width: 2px;
    border-right-width: 0;
    border-color: #0e0e0e;
    font-size: 0.75rem;
}

@keyframes blur {
 0% {
    opacity: 0.5;
    transform: scale(0.8);
 }
 100%{
    opacity: 1;
    transform: none;
 }
}

.calendar-filler{
    padding: var(--calendar-day-x-padding);
    padding-top: var(--calendar-day-y-padding);
    padding-bottom:var(--calendar-day-y-padding);
    background-color: var(--filler-day-background-color);
    transition: ease-in 150ms;
    animation: blur 0.3s;
}

.calendar-day{
    padding: var(--calendar-day-x-padding);
    padding-top: var(--calendar-day-y-padding);
    padding-bottom:var(--calendar-day-y-padding);
    position:relative;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;
    border-right: thin solid #f0f0f0;
    border-bottom: thin solid #f0f0f0;
    transition: ease-in 150ms;
    animation: blur 0.3s;
}

.past-calendar-day {
    background-color: #e2e2e2;
    opacity: 0.6;
}

.calendar-date-indicator{
   position: absolute;
   top: 2px;
   left:2px;
   padding: 0.2rem;
}

.calendar-day-event-list{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;

}

.calendar-day-event-item{
    display: flex;
    gap:0.2rem;
    align-items:center;
    justify-content: space-between;
    background-color: white;
    border: solid thin #a2a2a2;
    width:100%;
    padding:0.1rem;
}

.calendar-day-event-item:hover{
    font-weight:600;
    padding: 0.11rem;
    transition: ease-in 150ms;
}
.room-type-indicator{
    height:max(0.25rem,100%);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: 0.25rem;

}
.room-rate-value{
    padding:0.1rem;
}

.year-text:hover{
    cursor:pointer;
}
.year-selections{
    position:absolute;
    background:white;
    z-index:2;
    padding-left:2rem 1.5rem;
    top:2rem;
    left: 1rem;
    max-height: 10rem;
    overflow:auto;
    min-width: 8rem;
    border: solid thin #eee;
    scrollbar-width:none;
}

.year-selection:hover{
    background-color: #afafaf;
    color: #fefefe;
    font-weight: 600;
    cursor:pointer;
}

.selected-year{

    background-color: #afafaf;
}

